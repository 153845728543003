/* eslint-disable no-unused-vars */
// Import Modules
import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Outlet, useNavigate } from 'react-router-dom'

// Import Handlers
import { getDocumentosRecibidos, updateDocumentRecibido } from 'actions/documentos'

// Import  StyleSheet
import './DocumentosRecibidos.css'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

// Import Components
import Resumen from '../Resumen/ResumenRecibidos'

// Import Context
import useUser from 'hooks/useUser'
import Global from '../../../contexts/Global/Global'
import useDocumentos from 'hooks/useDocumentos'
import { useDispatch } from 'react-redux'
import { formatDate, parseDates } from 'helpers/date'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import KeyviewDocumentosRecibidos from '../KeyviewDocumentosRecibidos'
import DocumentReader from 'components/DocumentReader/DocumentReader'

import { getInstance } from 'api/apiInstance'

const DocumentosRecibidos = () => {
  const globalContext = useContext(Global)
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { documentosRecibidos, isLoadingDocumentosRecibidos } = useDocumentos()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [openKeyview, setOpenKeyview] = useState(false)
  const [keyviewType, setKeyviewType] = useState(null)
  const [dateState, setDateState] = useState(null)
  const [documentosRecibidosFiltrados, setDoucumentosRecibidosFiltrados] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filtros, setFiltros] = useState({
    search: '',
    tipo: '',
    estado: '',
  })
  const [download, setDownload] = useState(false)
  const [lectorState, setLectorState] = useState({
    fileUrl: null,
    base64PDF: null,
    base64XML: null,
    title: null,
  })

  const getDocumentoForID = (idDocumento) => {
    try {
      return documentosRecibidos.find((doc) => doc.docRecibidosPK.id.toString() === idDocumento)
      /* if (sectionLector === 'documentosRecibidos') {
        if (documentosRecibidos) {
        }
      } else if (sectionLector === 'documentosEmitidos') {
        if (documentosEmitidos) {
          return documentosEmitidos.find((doc) => doc.vtaMovmPK.numDocto.toString() === idDocumento)
        }
      } */
    } catch (error) {
      console.error(error)
    }
  }

  /*  const getBase64PDF = async (documentoForID) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        let base64 = await axios(
          `https://test.facturacionsavetaxcr.com/savetax-test/api/v1/doc-recibido/pdf-base64/${savetaxInfo.cia.codCia}/${documentoForID.docRecibidosPK.id}`
        )
        return base64.data
      } else if (sectionLector === 'documentosEmitidos') {
        let base64 = await axios(
          `https://test.facturacionsavetaxcr.com/savetax-test/api/v1/vta-movm/pdf-base64/${savetaxInfo.cia.codCia}/${documentoForID.tipoDoctoVta.idTipoDoctoVta}/1/${documentoForID.vtaMovmPK.numDocto}`
        )
        return base64.data
      }
    } catch (error) {
      console.log(error)
    }
  }


  const getFileUrl = (documentoForID) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        return `https://test.facturacionsavetaxcr.com/savetax-test/api/v1/doc-recibido/pdf/${savetaxInfo.cia.codCia}/${documentoForID.docRecibidosPK.id}`
      } else if (sectionLector === 'documentosEmitidos') {
        return `https://test.facturacionsavetaxcr.com/savetax-test/api/v1/vta-movm/pdf/${savetaxInfo.cia.codCia}/${documentoForID.tipoDoctoVta.idTipoDoctoVta}/${documentoForID.sucursal.sucursalPK.idSucursal}/${documentoForID.vtaMovmPK.numDocto}`
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getTitleDoc = (documentoData) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        return `${documentoData.tipoDoctoVta.nombreDoctoVta} ${documentoData.emisor} #${params.idDocumento}`
      } else if (sectionLector === 'documentosEmitidos') {
        return `${documentoData.tipoDoctoVta.nombreDoctoVta} ${documentoData.cliente.nombreCliente} #${params.idDocumento}`
      }
    } catch (error) {
      console.log(error)
    }
  } */

  const getBase64Key = async (documentoForID) => {
    try {
      const request = await getInstance('documentos')
      const base64 = await request.get(`doc-recibido/xml-base64/${savetaxInfo.cia.codCia}/${documentoForID}`)
      return base64.data
    } catch (error) {
      console.log(error)
    }
  }

  const cargarDocumento = async (idDocumento) => {
    let base64Key = await getBase64Key(idDocumento)
    setLectorState({
      base64XML: base64Key,
    })
    setDownload(true)
  }

  const nextPage = () => {
    setCurrentPage((p) => p + 1)
  }

  const prevPage = () => {
    setCurrentPage((p) => p - 1)
  }

  const clearFilters = () => {
    setFiltros({
      search: '',
      tipo: '',
      estado: '',
    })
    setDateState(null)
  }

  const filterForDate = (documentosMatched, value) => {
    let result = documentosMatched
    if (value) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(value[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(value[1]).getTime()
      )
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) => doc.emisor.toUpperCase().includes(filtros.search.toUpperCase()) || doc.nitEmisor.includes(filtros.search)
      )
    }

    if (result.length && filtros.tipo.length) {
      result = result.filter((doc) => doc.idTipoDoctoVta.toString() === filtros.tipo)
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estado
        }
      })
    }
    return result
  }

  const filterForSearch = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => doc.emisor.toUpperCase().includes(value.toUpperCase()) || doc.nitEmisor.includes(value))
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    if (result.length && filtros.tipo.length) {
      result = result.filter((doc) => doc.idTipoDoctoVta.toString() === filtros.tipo)
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estado
        }
      })
    }
    return result
  }

  const filterTipo = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => doc.idTipoDoctoVta.toString() === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) => doc.emisor.toUpperCase().includes(filtros.search.toUpperCase()) || doc.nitEmisor.includes(filtros.search)
      )
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estado
        }
      })
    }

    return result
  }

  const filterForEstado = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === value
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) => doc.emisor.toUpperCase().includes(filtros.search.toUpperCase()) || doc.nitEmisor.includes(filtros.search)
      )
    }

    if (result.length && filtros.tipo.length) {
      result = result.filter((doc) => doc.idTipoDoctoVta.toString() === filtros.tipo)
    }

    return result
  }

  const filterDocumentos = (name, value) => {
    let documentosMatched = documentosRecibidos
    if (documentosMatched.length) {
      if (name === 'search') {
        return filterForSearch(documentosMatched, value)
      } else if (name === 'tipo') {
        return filterTipo(documentosMatched, value)
      } else if (name === 'date') {
        return filterForDate(documentosMatched, value)
      } else if (name === 'estado') {
        return filterForEstado(documentosMatched, value)
      } else if (name === 'clear') {
        return documentosMatched
      }
    } else {
      return []
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target

    setDoucumentosRecibidosFiltrados(filterDocumentos(name, value))
    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  /* const changeTitle = (title) => {
    let keyView = document.querySelector('.keyView h3 .title')
    keyView.innerHTML = title
  }

  const fillAction = (doc, action) => {
    let tipoDocumento = document.querySelector('.keyView .tipoDocumento .dato')
    let claveDocumento = document.querySelector('.keyView .claveDocumento .dato')
    tipoDocumento.innerHTML = doc.tipoDoctoVta.nombreDoctoVta
    claveDocumento.innerHTML = doc.clave
    globalContext.updateGlobalContext('formData', {
      action,
    })
  } */

  const handleDropDownFilter = (event) => {
    let dropDownFilter = document.querySelector('.dropDownFilter')
    let target = event.target
    if (dropDownFilter.className.includes('toggled')) {
      dropDownFilter.className = 'dropDownFilter'
      target.className = 'toggledFilters toggled'
    } else {
      dropDownFilter.className = 'dropDownFilter toggled'
      target.className = 'toggledFilters'
    }
  }

  const updateRangeDate = (date) => {
    setDateState(date)
    setDoucumentosRecibidosFiltrados(filterDocumentos('date', date))
  }

  const handleDownloadResumen = async () => {
    if (!dateState) return toast.warning('Por favor ingrese un fecha para el resumen')

    const [parsedFechaInicio, parsedFechaFinal] = parseDates(dateState)
    const { search, tipo } = filtros
    const nombre = () => (search.length ? { nombre: search } : {})
    const tipoObj = () => (tipo.length ? { tipo: tipo } : {})

    const params = new URLSearchParams({
      codCia: savetaxInfo.cia.codCia,
      fechaInicio: parsedFechaInicio,
      fechaFin: parsedFechaFinal,
      ...nombre(),
      ...tipoObj(),
    })

    const request = await getInstance('documentos')
    const response = await request.get(`doc-recibido/find-by-cod-cia-parameters-archivo?${params.toString()}`)

    const a = document.createElement('a')
    a.href = 'data:application/octet-stream;base64,' + response.data
    a.download = 'documentos_recibidos_resumen.xlsx'
    a.click()
  }

  const aceptarDocumento = (index) => {
    setOpenKeyview(true)
    setKeyviewType('accept')
    setSelectedId(index)
  }

  const rechazarDocumento = (index) => {
    setOpenKeyview(true)
    setKeyviewType('reject')
    setSelectedId(index)
  }

  const onCloseKeyview = () => {
    setOpenKeyview(false)
    setKeyviewType(null)

    if (!open) {
      setSelectedId(null)
    }
  }

  /* const hadleAceptarDoc = async (doc) => {
    createAlertConfirm(
      `¿Estás seguro de aceptar el documento #${doc.docRecibidosPK.id}?`,
      `Podras aceptar el documento ${doc.docRecibidosPK.id}, esta accion no es puede ser revertida`,
      async () => {
        try {
          toast.success(`Documento #${doc.docRecibidosPK.id} fue aceptado`)
        } catch (error) {
          console.log(error)
        }
      }
    )
  }

  const hadleRechazarDoc = async (doc) => {
    createAlertConfirm(
      `¿Estás seguro de rechazar el documento #${doc.docRecibidosPK.id}?`,
      `Podras rechazar el documento ${doc.docRecibidosPK.id}, esta accion no es puede ser revertida`,
      async () => {
        try {
          toast.success(`Documento #${doc.docRecibidosPK.id} fue rechazado`)
        } catch (error) {
          console.log(error)
        }
      }
    )
  } */

  useEffect(() => {
    if (!documentosRecibidos.length) {
      dispatch(getDocumentosRecibidos(savetaxInfo.cia.codCia))
    }

    setDoucumentosRecibidosFiltrados(documentosRecibidos)
  }, [dispatch, documentosRecibidos, savetaxInfo])

  useEffect(() => {
    setDoucumentosRecibidosFiltrados(documentosRecibidos)
  }, [documentosRecibidos])

  return (
    <>
      <div className="DocumentosRecibidos" onScroll={hadleScrollSection}>
        <div className="navSection">
          <button className="toggledFilters" onClick={handleDropDownFilter}>
            <i className="bi bi-chevron-down"></i>
          </button>
          <div className="dropDownFilter toggled">
            <label htmlFor="" style={{ width: '40%' }}>
              Nombre o Cedula de Emisor
              <div className="search">
                <input
                  value={filtros.search.toUpperCase()}
                  type="text"
                  placeholder="Nombre o cedula de Emidor"
                  onChange={hadleFilter}
                  name="search"
                />
                <button>
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </label>
            <label htmlFor="" style={{ width: '20%' }}>
              Tipo de Documento
              <select id="" value={filtros.tipo} onChange={hadleFilter} name="tipo">
                <option value="">Todos los Tipos</option>
                <option value="2">Factura Electronica</option>
                <option value="5">Tiqueta Electronica</option>
                <option value="3">Nota Debito Electronica</option>
                <option value="4">Nota Credito Electronica</option>
                <option value="6">Factura Electronica Exportación</option>
                <option value="7">Factura Electronica de Compra</option>
                <option value="1">Proforma Electronica</option>
              </select>
            </label>
            <label htmlFor="" style={{ width: '20%' }}>
              Estado de Receptor
              <select name="estado" id="" value={filtros.estado} onChange={hadleFilter}>
                <option value="">Todos los Estados</option>
                <option value="PENDIENTE">Pendiente</option>
                <option value="ACEPTADO">Aceptado</option>
                <option value="RECHAZADO">Rechazado</option>
              </select>
            </label>
            <label htmlFor="" style={{ width: '20%' }} className="togglebled">
              Estado Hacienda
              <select name="estado" id="" value={filtros.estado} onChange={hadleFilter}>
                <option value="">Todos los Estados</option>
                <option value="PENDIENTE">Pendiente</option>
                <option value="ACEPTADO">Aceptado</option>
                <option value="RECHAZADO">Rechazado</option>
              </select>
            </label>
            <label htmlFor="" className="togglebled">
              Rango de fecha
              <DateRangePicker onChange={(date) => updateRangeDate(date)} value={dateState} />
            </label>
            <label htmlFor="" style={{ width: '20%' }} onClick={clearFilters}>
              Limpiar Filtros
              <button className="clearFilterButton">Limpiar</button>
            </label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            <button className="addContent" onClick={handleDownloadResumen}>
              Descargar Resumen
            </button>
          </div>
        </div>
        {documentosRecibidosFiltrados.length > 0 && <Resumen date={dateState} docs={documentosRecibidosFiltrados} />}
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1-2">
                <b>Tipo</b>
              </li>
              <li className="col-2">
                <b>Emisor</b>
              </li>
              <li className="col-1">
                <b>Emisión</b>
              </li>
              <li className="col-1">
                <b>Moneda</b>
              </li>
              <li className="col-1">
                <b>Impuestos</b>
              </li>
              <li className="col-1">
                <b>Monto</b>
              </li>
              <li className="col-1">
                <b>Hacienda</b>
              </li>
              <li className="col-1">
                <b>Recepción</b>
              </li>
              <li className="col-2-2">
                <b></b>
              </li>
            </ul>
          </div>
          {isLoadingDocumentosRecibidos ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Documentos Recibidos</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {documentosRecibidosFiltrados.length > 0 &&
                  documentosRecibidosFiltrados.slice(50 * currentPage - 50, 50 * currentPage).map((doc, index) => (
                    <li className="itemBodyList" key={`documento-recibido-${index}`}>
                      <ul className="contentItemBodyList">
                        <li className="col-1-2">
                          <span>{doc.tipoDoctoVta.nombreCortoVta}</span>
                        </li>
                        <li className="col-2">
                          <span>{doc.emisor}</span>
                        </li>
                        <li className="col-1">
                          <span>{formatDate(doc.fechaEmision)}</span>
                        </li>
                        <li className="col-1">
                          <span>{doc.idMoneda.nombreCorto}</span>
                        </li>
                        <li className="col-1">
                          <span>{doc.montoImpuesto.toLocaleString('es')}</span>
                        </li>
                        <li className="col-1">
                          <span>{doc.totalFactura.toLocaleString('es')}</span>
                        </li>
                        <li className="col-1">
                          <span className={doc.descripcionSri ? 'done' : 'panding'}>
                            {doc.descripcionSri ? (doc.descripcionSri === 'ACEPTADO' ? 'ACEPTADO' : 'RECHAZADO') : 'PENDIENTE'}
                          </span>
                        </li>
                        <li className="col-1">
                          <span className={doc.descripcionSri ? 'done' : 'panding'}>
                            {doc.descripcionSri ? (doc.descripcionSri === 'ACEPTADO' ? 'ACEPTADO' : 'RECHAZADO') : 'PENDIENTE'}
                          </span>
                        </li>
                        <li className="col-2-2">
                          {!doc.descripcionSri ? (
                            <button className="btnAceptar" title="Aceptar documento" onClick={() => aceptarDocumento(index)}>
                              <i className="bi bi-check-circle"></i>
                            </button>
                          ) : (
                            <button
                              disabled
                              className="btnAceptar"
                              style={{ opacity: 0.4, cursor: 'initial' }}
                              title="Aceptar documento"
                              onClick={null}
                            >
                              <i className="bi bi-check-circle"></i>
                            </button>
                          )}
                          {!doc.descripcionSri ? (
                            <button className="btnDelete" title="Rechazar documento" onClick={() => rechazarDocumento(index)}>
                              <i className="bi bi-x-circle"></i>
                            </button>
                          ) : (
                            <button
                              className="btnDelete"
                              title="Rechazar documento"
                              style={{ opacity: 0.4, cursor: 'initial' }}
                              disabled
                              onClick={null}
                            >
                              <i className="bi bi-x-circle"></i>
                            </button>
                          )}
                          <button
                            title="Ver documento"
                            onClick={(e) => {
                              navigate(`/documentosRecibidos/${doc.docRecibidosPK.id}`)
                            }}
                          >
                            <i className="bi bi-file-earmark-text"></i>
                          </button>
                          <button
                            title="Descargar XML"
                            onClick={(e) => {
                              cargarDocumento(doc.docRecibidosPK.id)
                            }}
                          >
                            <i className="bi bi-filetype-xml"></i>
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))}

                {!documentosRecibidosFiltrados.length && (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        {!isLoadingDocumentosRecibidos && (
          <div className="paginationTable">
            <div className="controllers">
              {currentPage > 1 && (
                <button className="previousPage" onClick={prevPage}>
                  <i className="bi bi-chevron-left"></i>
                </button>
              )}
              <span>
                Página {currentPage} de {Math.ceil(documentosRecibidosFiltrados.length / 50)}
              </span>
              {documentosRecibidosFiltrados.length > 50 && !(currentPage * 50 >= documentosRecibidosFiltrados.length) && (
                <button className="nextPage" onClick={nextPage}>
                  <i className="bi bi-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <Outlet />
      <KeyviewDocumentosRecibidos
        documents={documentosRecibidosFiltrados}
        selectedId={selectedId}
        open={openKeyview}
        type={keyviewType}
        onClose={onCloseKeyview}
      />
      {download && (
        <div className="modal-download">
          <h4 className="title-download">Descargar XML</h4>
          <div className="buttons-download">
            <button className="button-download" onClick={() => setDownload(false)}>
              Cancelar
            </button>
            <a
              className="button-download"
              href={'data:application/octet-stream;base64,' + lectorState.base64XML}
              title="Descargar XML"
            >
              Aceptar
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default DocumentosRecibidos
