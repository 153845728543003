// Import Modules
import React from 'react'

// Import Handlers
import { saveAs } from 'file-saver'
import { useDispatch } from 'react-redux'
import { deleteFactura } from 'actions/facturas'

import { getURL } from 'api/apiInstance'
import { formatDate } from 'helpers/date'

const TableItem = ({ factura, createAlertConfirm, onClickSee, onCopy }) => {
  const dispatch = useDispatch()

  const handleDeleteRecipe = (doc) => {
    createAlertConfirm(
      `¿Estás seguro de eliminar ${doc.referencia}?`,
      `Podras eliminar el documento ${doc.referencia}, esta accion no es puede ser revertida`,
      () => {
        dispatch(deleteFactura(doc))
      }
    )
  }

  return (
    <li className="itemBodyList">
      <ul className="contentItemBodyList">
        <li className="col-2">
          <span>{factura.cliente.nombreCliente}</span>
        </li>
        {/* <li className="col-1">
          <span>{factura.descripcionSri ? factura.prefijo : 'No disponible'}</span>
        </li> */}
        <li className="col-1">
          <b>{factura.descripcionSri ? factura.referencia : 'No disponible'}</b>
        </li>
        <li className="col-1 moneda">
          <span>{factura.idMoneda.nombreCorto}</span>
        </li>
        <li className="col-1">
          <span>
            {factura.idMoneda.signo} {factura.total !== 0 ? factura.total.toLocaleString('es') : <p>-</p>}
          </span>
        </li>
        <li className="col-1">
          <span>{factura.descripcionSri ? formatDate(factura.fechaEmision) : 'No definida'}</span>
        </li>
        <li className="col-2">
          <span
            className={
              (factura.descripcionSri === 'ACEPTADO' && 'done') ||
              (!factura.descripcionSri && 'pending') ||
              (factura.descripcionSri === 'RECHAZADO' && 'cancel')
            }
          >
            {factura.descripcionSri ? factura.descripcionSri : 'PENDIENTE'}
          </span>
        </li>
        <li className="col-2">
          <span
            className={
              (factura.status === 'G' && 'info') || (factura.status === 'A' && 'cancel') || (factura.status === 'R' && '')
            }
          >
            {factura.status === 'G' && 'GENERADO'}
            {factura.status === 'A' && 'ANULADO'}
            {factura.status === 'R' && 'REGISTRADO'}
          </span>
        </li>
        <li className="col-1">
          <button className="btnEditItem" onClick={onClickSee}>
            <i className="bi bi-eye"></i>
          </button>
          <button className="btnDelete" onClick={(event) => handleDeleteRecipe(factura)}>
            <i className="bi bi-trash"></i>
          </button>
          <button
            className="btnEditItem"
            onClick={() =>
              saveAs(
                `${getURL('fe')}vta-movm/pdf/` +
                  factura.vtaMovmPK.codCia +
                  '/' +
                  factura.vtaMovmPK.idTipoDoctoVta +
                  '/' +
                  factura.vtaMovmPK.idSucursal +
                  '/' +
                  factura.vtaMovmPK.numDocto +
                  '',
                `Factura Electronica ${factura.prefijo} ${factura.referencia}.pdf`
              )
            }
          >
            <i className="bi bi-file-earmark-text"></i>
          </button>
          <button className="btnEditItem" onClick={onCopy}>
            <i className="bi bi-clipboard"></i>
          </button>
        </li>
      </ul>
    </li>
  )
}

export default TableItem
