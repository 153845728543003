import { DateTime } from 'luxon'

export const parseDates = (dateArray) => {
  const [inicio, final] = dateArray
  const isoInicio = inicio.toISOString()
  const isoFinal = final.toISOString()

  const parsedInicio = DateTime.fromISO(isoInicio).toFormat('dd-MM-yyyy')
  const parsedFinal = DateTime.fromISO(isoFinal).toFormat('dd-MM-yyyy')

  return [parsedInicio, parsedFinal]
}

export const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('T')[0].split('-')
  return `${day}/${month}/${year}`
}

/*export const formatDate = (dateString) => {
  return DateTime.fromISO(dateString).toFormat('dd/MM/yyyy')
}*/
