// Import Modules
import React, { useState, useEffect } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { toast } from 'react-toastify'
import { Outlet, useNavigate } from 'react-router-dom'
import { formatDate } from 'helpers/date'

// Import Components
import Resumen from '../Resumen/Resumen'

// Import StyleSheet
import './DocumentosEmitidos.css'

// Import Hooks
import useUser from 'hooks/useUser'
import useDocumentos from 'hooks/useDocumentos'
import { getDocumentosEmitidos, getDocumentosEmitidosFilter } from 'actions/documentos'
import { useDispatch } from 'react-redux'
import { parseDates } from 'helpers/date'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import { deleteFactura } from 'actions/facturas'
import { getInstance } from 'api/apiInstance'

const DocumentosEmitidos = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { documentosEmitidos, isLoadingDocumentosEmitidos } = useDocumentos()
  const navigate = useNavigate()

  const [dateState, setDateState] = useState(null)
  const [download, setDownload] = useState(false)
  const [lectorState, setLectorState] = useState({
    fileUrl: null,
    base64PDF: null,
    base64XML: null,
    title: null,
  })
  const [documentosEmitidosFiltrados, setDocumentosEmitidosFiltrados] = useState([])
  const [totales, setTotales] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filtros, setFiltros] = useState({
    search: '',
    tipo: '',
    estado: '',
  })

  const nextPage = () => {
    setCurrentPage((p) => p + 1)
  }

  const prevPage = () => {
    setCurrentPage((p) => p - 1)
  }

  const clearFilters = () => {
    setFiltros({
      search: '',
      tipo: '',
      estado: '',
    })
    setDateState(null)
    setDocumentosEmitidosFiltrados(documentosEmitidos)
  }

  const filterForDate = (documentosMatched, value) => {
    console.log('por fecha')
    let result = documentosMatched
    if (value) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(value[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(value[1]).getTime()
      )
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          doc.cliente.ruc.includes(filtros.search)
      )
    }

    if (result.length && filtros.tipo.length) {
      result = result.filter((doc) => doc.tipoDoctoVta.idTipoDoctoVta.toString() === filtros.tipo)
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estado
        }
      })
    }
    return result
  }

  const filterForSearch = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter(
        (doc) => doc.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || doc.cliente.ruc.includes(value)
      )
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    if (result.length && filtros.tipo.length) {
      result = result.filter((doc) => doc.tipoDoctoVta.idTipoDoctoVta.toString() === filtros.tipo)
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estado
        }
      })
    }
    return result
  }

  const filterTipo = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => doc.tipoDoctoVta.idTipoDoctoVta.toString() === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          doc.cliente.ruc.includes(filtros.search)
      )
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estado
        }
      })
    }

    return result
  }

  const filterForEstado = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === value
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          doc.cliente.ruc.includes(filtros.search)
      )
    }

    if (result.length && filtros.tipo.length) {
      result = result.filter((doc) => doc.tipoDoctoVta.idTipoDoctoVta.toString() === filtros.tipo)
    }

    return result
  }

  const filterDocumentos = (name, value) => {
    let documentosMatched = documentosEmitidos
    if (documentosMatched.length) {
      if (name === 'search') {
        return filterForSearch(documentosMatched, value)
      } else if (name === 'tipo') {
        return filterTipo(documentosMatched, value)
      } else if (name === 'date') {
        return filterForDate(documentosMatched, value)
      } else if (name === 'estado') {
        return filterForEstado(documentosMatched, value)
      } else if (name === 'clear') {
        return documentosMatched
      }
    } else {
      return []
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target

    setFiltros((prev) => ({ ...prev, [name]: value }))
    setCurrentPage(1)
    setDocumentosEmitidosFiltrados(filterDocumentos(name, value))
  }

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  const updateRangeDate = async (date) => {
    setDateState(date)

    console.log(date)
    if (!date) return
    const [parsedFechaInicio, parsedFechaFinal] = parseDates(date)
    console.log('aaaa')
    const params = new URLSearchParams({
      codCia: savetaxInfo.cia.codCia,
      fechaInicio: parsedFechaInicio,
      fechaFin: parsedFechaFinal,
    })

    const request = await getInstance('fe')
    const response = await request.get(`vta-movm/find-totales-doc-emitidos?${params.toString()}`)
    console.log(response.data)
    setTotales(response.data)
    dispatch(getDocumentosEmitidosFilter(params.toString()))
    setDocumentosEmitidosFiltrados(documentosEmitidos)
  }

  const hadleAnularDoc = async (doc) => {
    createAlertConfirm(
      `¿Estás seguro de anular ${doc.referencia}?`,
      `Podras anular el documento ${doc.referencia}, esta accion no es puede ser revertida`,
      async () => {
        try {
          dispatch(deleteFactura(doc))
          toast.success(`Documento ${doc.referencia} fue anulada`)
        } catch (error) {
          console.log(error)
        }
      }
    )
  }

  const handleDownloadConDetalle = async () => {
    if (!dateState) return toast.warning('Por favor ingrese un fecha para el reporte')

    const [parsedFechaInicio, parsedFechaFinal] = parseDates(dateState)
    const { search, tipo } = filtros
    const nombre = () => (search.length ? { nombre: search } : {})
    const tipoObj = () => (tipo.length ? { tipo: tipo } : {})

    const params = new URLSearchParams({
      codCia: savetaxInfo.cia.codCia,
      fechaInicio: parsedFechaInicio,
      fechaFin: parsedFechaFinal,
      ...nombre(),
      ...tipoObj(),
    })

    const request = await getInstance('fe')
    const response = await request.get(`vta-movm/find-by-cod-cia-parameters-archivo-detalle?${params.toString()}`)

    const a = document.createElement('a')
    a.href = 'data:application/octet-stream;base64,' + response.data
    a.download = 'documentos_emitidos_detalle.xlsx'
    a.click()
  }

  const handleDownloadResumen = async () => {
    if (!dateState) return toast.warning('Por favor ingrese un fecha para el resumen')

    const [parsedFechaInicio, parsedFechaFinal] = parseDates(dateState)
    const { search, tipo } = filtros
    const nombre = () => (search.length ? { nombre: search } : {})
    const tipoObj = () => (tipo.length ? { tipo: tipo } : {})

    const params = new URLSearchParams({
      codCia: savetaxInfo.cia.codCia,
      fechaInicio: parsedFechaInicio,
      fechaFin: parsedFechaFinal,
      ...nombre(),
      ...tipoObj(),
    })

    const request = await getInstance('fe')
    const response = await request.get(`vta-movm/find-by-cod-cia-parameters-archivo-resumen?${params.toString()}`)

    const a = document.createElement('a')
    a.href = 'data:application/octet-stream;base64,' + response.data
    a.download = 'documentos_emitidos_resumen.xlsx'
    a.click()
  }

  const getBase64Key = async (documentoForID) => {
    try {
      const request = await getInstance('documentos')
      const base64 = await request.get(`doc-recibido/xml-base64/${savetaxInfo.cia.codCia}/${documentoForID}`)
      return base64.data
    } catch (error) {
      console.log(error)
    }
  }

  const cargarDocumento = async (idDocumento) => {
    let base64Key = await getBase64Key(idDocumento)
    setLectorState({
      base64XML: base64Key,
    })
    setDownload(true)
  }

  useEffect(() => {
    if (isLoadingDocumentosEmitidos) {
      console.log(isLoadingDocumentosEmitidos)
      dispatch(getDocumentosEmitidos(savetaxInfo.cia.codCia))
    }
    setDocumentosEmitidosFiltrados(documentosEmitidos)
  }, [dispatch, documentosEmitidos, savetaxInfo, isLoadingDocumentosEmitidos])

  useEffect(() => {
    setDocumentosEmitidosFiltrados(documentosEmitidos)
  }, [documentosEmitidos])

  return (
    <div className="DocumentosRecibidos" onScroll={hadleScrollSection}>
      <div className="navSection">
        <div className="dropDownFilter ">
          <label htmlFor="" style={{ width: '40%' }}>
            Nombre o Cedula de Emisor
            <div className="search">
              <input
                value={filtros.search.toUpperCase()}
                type="text"
                placeholder="Nombre o cedula de Emidor"
                onChange={hadleFilter}
                name="search"
              />
              <button>
                <i className="bi bi-search"></i>
              </button>
            </div>
          </label>
          <label htmlFor="" style={{ width: '20%' }}>
            Tipo de Documento
            <select id="" value={filtros.tipo} onChange={hadleFilter} name="tipo">
              <option value="">Todos los Tipos</option>
              <option value="2">Factura Electronica</option>
              <option value="5">Tiqueta Electronica</option>
              <option value="3">Nota Debito Electronica</option>
              <option value="4">Nota Credito Electronica</option>
              <option value="6">Factura Electronica Exportación</option>
              <option value="7">Factura Electronica de Compra</option>
              <option value="1">Proforma Electronica</option>
            </select>
          </label>
          <label htmlFor="" style={{ width: '20%' }}>
            Estado de Receptor
            <select name="estado" id="" value={filtros.estado} onChange={hadleFilter}>
              <option value="">Todos los Estados</option>
              <option value="PENDIENTE">Pendiente</option>
              <option value="ACEPTADO">Aceptado</option>
              <option value="RECHAZADO">Rechazado</option>
            </select>
          </label>
          <label htmlFor="" style={{ width: '20%' }}>
            Estado Hacienda
            <select name="estado" id="" value={filtros.estado} onChange={hadleFilter}>
              <option value="">Todos los Estados</option>
              <option value="PENDIENTE">Pendiente</option>
              <option value="ACEPTADO">Aceptado</option>
              <option value="RECHAZADO">Rechazado</option>
            </select>
          </label>

          <label htmlFor="">
            Rango de fecha
            <DateRangePicker onChange={(date) => updateRangeDate(date)} value={dateState} />
          </label>

          <label htmlFor="" style={{ width: '25%' }}>
            Limpiar Filtros
            <button className="clearFilterButton" onClick={clearFilters}>
              Limpiar
            </button>
          </label>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          <button className="addContent" onClick={handleDownloadResumen}>
            Descargar Resumen
          </button>
          <button className="addContent" onClick={handleDownloadConDetalle}>
            Descargar Detalle
          </button>
        </div>
      </div>
      {totales.length > 0 && <Resumen date={dateState} docs={totales} />}
      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-1-2">
              <b>Tipo</b>
            </li>
            <li className="col-1">
              <b>Referencia</b>
            </li>
            <li className="col-2">
              <b>Emisor</b>
            </li>
            <li className="col-1">
              <b>Emisión</b>
            </li>
            <li className="col-1">
              <b>Moneda</b>
            </li>
            <li className="col-1">
              <b>Impuestos</b>
            </li>
            <li className="col-1">
              <b>Monto</b>
            </li>
            <li className="col-1">
              <b>Hacienda</b>
            </li>
            <li className="col-1-2">
              <b>Recepción</b>
            </li>
            <li className="col-1">
              <b></b>
            </li>
          </ul>
        </div>

        {isLoadingDocumentosEmitidos ? (
          <div className="loadContentTable">
            <div className="snippet"></div>
            <span>Cargando Documentos Emitidos</span>
          </div>
        ) : (
          <div className="bodyList">
            <ul>
              {documentosEmitidosFiltrados.length > 0 &&
                documentosEmitidosFiltrados.slice(50 * currentPage - 50, 50 * currentPage).map((doc, index) => (
                  <li className="itemBodyList" key={`documento-emitido-${index}`}>
                    <ul className="contentItemBodyList">
                      <li className="col-1-2">
                        <span>{doc.tipoDoctoVta.nombreCortoVta}</span>
                      </li>
                      <li className="col-1">
                        <b>{doc.referencia ? doc.referencia : 'No disponible'}</b>
                      </li>
                      <li className="col-2">
                        <span>{doc.cliente.nombreCliente}</span>
                      </li>
                      <li className="col-1">
                        <span>{formatDate(doc.fechaEmision)}</span>
                      </li>
                      <li className="col-1">
                        <span>{doc.idMoneda.nombreCorto}</span>
                      </li>
                      <li className="col-1">
                        <span>
                          {doc.idMoneda.signo} {doc.iva.toLocaleString('es')}
                        </span>
                      </li>
                      <li className="col-1">
                        <span>
                          {doc.idMoneda.signo} {doc.total.toLocaleString('es')}
                        </span>
                      </li>
                      <li className="col-1">
                        <span className={doc.descripcionSri ? 'done' : 'panding'}>
                          {doc.descripcionSri ? doc.descripcionSri : 'PENDIENTE'}
                        </span>
                      </li>
                      <li className="col-1-2">
                        <span className={doc.descripcionSri ? 'done' : 'panding'}>
                          {doc.descripcionSri ? doc.descripcionSri : 'PENDIENTE'}
                        </span>
                      </li>
                      <li className="col-1">
                        <button className="btnDelete" onClick={(event) => hadleAnularDoc(doc, event)} title="Anular documento">
                          <i className="bi bi-trash"></i>
                        </button>
                        <button
                          title="Descargar XML"
                          onClick={(e) => {
                            cargarDocumento(doc.cliente.clientePK.idCliente)
                          }}
                        >
                          <i className="bi bi-filetype-xml"></i>
                        </button>
                        <button
                          title="Ver documento"
                          onClick={(e) => {
                            navigate(`/documentosEmitidos/${doc.vtaMovmPK.numDocto}`)
                          }}
                        >
                          <i className="bi bi-file-earmark-text"></i>
                        </button>
                      </li>
                    </ul>
                  </li>
                ))}

              {!documentosEmitidosFiltrados.length && (
                <li className="emptyResultTable">
                  <span>No hay Resultados</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      {!isLoadingDocumentosEmitidos && (
        <div className="paginationTable">
          <div className="controllers">
            {currentPage > 1 && (
              <button className="previousPage" onClick={prevPage}>
                <i className="bi bi-chevron-left"></i>
              </button>
            )}
            <span>
              Página {currentPage} de {Math.ceil(documentosEmitidosFiltrados.length / 50)}
            </span>
            {documentosEmitidosFiltrados.length > 50 && !(currentPage * 50 >= documentosEmitidosFiltrados.length) && (
              <button className="nextPage" onClick={nextPage}>
                <i className="bi bi-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
      )}
      {download && (
        <div className="modal-download">
          <h4 className="title-download">Descargar XML</h4>
          <div className="buttons-download">
            <button className="button-download" onClick={() => setDownload(false)}>
              Cancelar
            </button>
            <a
              className="button-download"
              href={'data:application/octet-stream;base64,' + lectorState.base64XML}
              title="Descargar XML"
            >
              Aceptar
            </a>
          </div>
        </div>
      )}
      <Outlet />
    </div>
  )
}

export default DocumentosEmitidos
